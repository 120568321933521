// vue
import { ref, reactive, computed, watch, onMounted, onBeforeUnmount } from 'vue';

// shared
import { BOTS_DATA_FORM_FACTORY, VA_PD_FILTER_FACTORY } from '@shared/factories';
import { formatUnexistedCurrsAsUnknownObject } from '@shared/util';

// router
import { useRoute, useRouter } from 'vue-router';

// store
import { useGl } from '@store/ts/gl';
import { useBots } from '@store/bots';
import { useRefs } from '@store/ts/refs';
import { useTools } from '@store/tools';

// services
import BotsService from'@services/bots';
import ApiKeysService from'@services/apiKeysService';
import WalletsService from '@services/walletsService';
import ExchangesService from '@services/exchangesService';
import TradeStatisticService from '@services/tradeStatisticService';

// i18n
import { useI18n } from 'vue-i18n';

// local services
import { GRID_ACTIONS } from '@components/bots/services';

// shared
import { CRUD_PAGER_FACTORY, BOTS_DATA_FORM_FACTORY_PREPARE } from '@shared/factories';

// naive-ui
import {
    useMessage,
    useNotification } from 'naive-ui';

export default function (props) {
    // store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();
    const tools = useTools();

    // i18n
    const { t } = useI18n();

    // naive-ui
    const notification = useNotification();
    const message = useMessage();

    // router
    const route = useRoute();
    const router = useRouter();

    // vars
    const { _ } = window;
    const init = ref(false);
    const walletsRefs = ref();
    const apiKeysRefs = ref();
    const exchangeInfo = ref();
    const tickerMessage = ref();
    const showStartTime = ref(false);
    const dataForm = bots.dataForm;
    const symbolsForMarketRefs = ref();
    const currPresetName = ref('default');
    const pager = reactive(CRUD_PAGER_FACTORY());

    const pairsRefs = computed(() => exchangeInfo.value?.pairs || []);
    const isUpdating = computed(() => props.gridAction === GRID_ACTIONS.ACTION_EDIT);
    const pairSymbols = computed(() => String(dataForm.pair.value).split('/').map(s => s.trim()));
    const applyLabel = computed(() => dataForm?.id ? bots.localization['bot_update_button'] : bots.localization['bot_create_button']);
    const isExchangeFutures = computed(() => !!refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.futures);
    const exchangeCommissionCoin = computed(() => refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.commissionCoin || false);

    const leverageRefs = computed(() => exchangeInfo.value?.leverage || {});
    const leverageMinMax = computed(() => leverageRefs.value[dataForm.pair.value] || {});

    const coinSymbolForWallet = computed(() => {
        const [fSymbol, sSymbol] = pairSymbols.value;
        if (isExchangeCoin.value) return fSymbol;
        if (isExchangeFutures.value) return sSymbol;
        return dataForm.algo.value === 'long' ? sSymbol : (dataForm.algo.value === 'short' ? fSymbol : null);
    });

    const pairWalletsRefs = computed(() => {
        if (!walletsRefs.value || dataForm.algo.value == -1) return [];
        return walletsRefs.value.filter(w => w.currency == coinSymbolForWallet.value);
    });

    const recommendedPairsRef = computed(() => {
        return tools.pairsInfo.map(el => {
            return {
                id: el.pair,
                disabled: !~pairsRefs.value.findIndex(({ id }) => id === el.pair),
                ...el,
            };
        });
    });

    const exchangeMeta2Info = computed(() => exchange(dataForm.exchange.value)?.meta2);
    const exchangeMeta3Info = computed(() => exchange(dataForm.exchange.value)?.meta3);

    const isExchangeSelected = computed(() => dataForm.exchange.value !== -1);
    const isPairSelected = computed(() => dataForm.pair.value !== -1);
    const isApiKeySelected = computed(() => dataForm.api_key.value !== -1);
    const isAlgoSelected = computed(() => dataForm.algo.value !== -1);
    const isExchangeCoin = computed(() => exchangeInfo.value?.extra.coin == 1);
    const isWalletSelected = computed(() => dataForm.wallet.value !== -1);
    const canCreateBot = computed(() => isAlgoSelected.value && dataForm.name.value.trim().length > 0);

    const algoOptions = computed(() => bots.algos.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const exchangesSorted = computed(() => {
        const data = Object.values(refs.exchanges)
            .sort((e1, e2) => e1.title > e2.title ? 1 : -1);

        return data.map(el => ({
            value: el.id,
            label: el.title,
            ...el,
        }));
    });

    const scrollWindow = top => {
        window.scrollTo({
            top,
            behavior: 'smooth',
        });
    };

    watch(() => dataForm.start_filters?.length, v => {
        dataForm.start_filters_enabled = v > 0;
    });

    watch(() => dataForm.api_key?.show, v => {
        if (v) {
            setTimeout(() => {
                const top = document.querySelector('#api_key').getBoundingClientRect().top;
                if (!gl.isMobile) {
                    document.querySelector('#main_container').scrollTo({
                        top,
                        behavior: 'smooth',
                    });
                } else {
                    scrollWindow(top);
                }
            }, 200);
        };
    });

    watch(() => dataForm.pair?.show, v => {
        if (v) {
            setTimeout(() => {
                const top = document.querySelector('#pair').getBoundingClientRect().top;
                if (!gl.isMobile) {
                    document.querySelector('#main_container').scrollTo({
                        top,
                        behavior: 'smooth',
                    });
                } else {
                    scrollWindow(top);
                }
            }, 200);
        };
    });

    watch(() => dataForm.algo?.show, v => {
        if (v) {
            setTimeout(() => {
                const top = document.querySelector('#algo').getBoundingClientRect().top;
                if (!gl.isMobile) {
                    document.querySelector('#main_container').scrollTo({
                        top,
                        behavior: 'smooth',
                    });
                } else {
                    scrollWindow(top);
                }
            }, 200);
        };
    });

    watch(() => dataForm.wallet?.show, v => {
        if (v) {
            setTimeout(() => {
                if (!gl.isMobile) {
                    document.querySelector('#main_container').scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                } else {
                    const top = document.querySelector('#wallet').getBoundingClientRect().top;
                    window.scrollTo({
                        top,
                        behavior: 'smooth',
                    });
                }
                
            }, 200);
        };
    });

    watch(() => dataForm.exchange?.value, async v => {
        if (!init.value) return;

        setLoading('exchange');
  
        exchangeInfo.value = null;
  
        dataForm.api_key.value = -1;
        apiKeysRefs.value = null;
  
        dataForm.pair.value = -1;
        dataForm.algo.value = -1;
  
        dataForm.wallet.value = -1;
        walletsRefs.value = null;
        dataForm.leverage.value = 0;
        dataForm.algo_autoinversion_enabled = false;
      
        if (v != -1) {
            try {
                // get Api Keys for this exchange
                await getApiKeysList(v);

                // get Pairs for this Exchange
                exchangeInfo.value = ( await ExchangesService.getFullInfo(v) ).data;
            } catch {
                return void message.error(t('errorMessage'));
            };

            dataForm.comm_type.value = exchangeCommissionCoin.value ? 'internal' : 'standard';
        }

        setLoading('exchange', false);
    });

    watch(() => dataForm.api_key?.value, async v => {
        if (!dataForm.api_key.show) return;

        setLoading('api_key');
  
        dataForm.wallet.value = -1;
        dataForm.leverage.value = 0;

        if (v != -1 && dataForm.pair.value != -1) {
            try {
                walletsRefs.value = ( await WalletsService.getWalletsList({
                    exchanges: [dataForm.exchange.value],
                    apikeys: [v],
                    pairs: [dataForm.pair.value],
                }) ).data.records;

                if (isWalletSelected.value) {
                    const i = walletsRefs.value.findIndex(({ id }) => id == dataForm.wallet.value);

                    ~i
                        ? dataForm.wallet.value = walletsRefs.value[i].id
                        : dataForm.wallet.value = -1;
                }
            } catch {
                message.error(t('errorMessage'));
                dataForm.wallet.value = -1;
            }
        }
        setLoading('api_key', false);
    });

    watch(() => dataForm.pair?.value, async v => {
        if (!init.value || !dataForm.pair.show) return;

        setLoading('pair');

        if (isExchangeSelected.value && isPairSelected.value) {
            TradeStatisticService.getTickerMessage({
                exchange_id: dataForm.exchange.value,
                pair: v,
            })
                .then(({ message }) => {
                    tickerMessage.value = message;
                })
                .catch(() => {
                    message.error(t('errorMessage'));
                });
        } else {
            tickerMessage.value = null;
        }

        dataForm.algo.value = -1;
        walletsRefs.value = null;
        dataForm.leverage.value = 0;

        if (v != -1 && dataForm.api_key.value != -1)
            try {
                walletsRefs.value = ( await WalletsService.getWalletsList({
                    exchanges: [dataForm.exchange.value],
                    pairs: [v],
                }) ).data.records;

                if (isWalletSelected.value)
                    dataForm.wallet.value = +v;
            } catch {
                message.error(t('errorMessage'));
                dataForm.pair.loading = false;
            };
      
        // await this.resetAutoInvRefs(newVal)
      
        // this.dataForm.settings.profit_coin = this.profitCoin
      
        // await this.root.installHelp(this.$rbBotForm, this.VMN)

        setLoading('pair', false);
    });

    watch(() => dataForm.settings?.profit_coin, async newVal => {
        if (!newVal || newVal == -1) return;

        try {
            var prepare = ( await ExchangesService.getSymbolsForMarket({
                exchange: dataForm.exchange.value,
                market: newVal,
            }) ).data;
        } catch {
            message.error(t('errorMessage'));
        }

        symbolsForMarketRefs.value = formatUnexistedCurrsAsUnknownObject(refs, prepare.symbols || []);
    });

    watch(() => dataForm.algo?.value, () => {
        dataForm.settings.profit_coin = coinSymbolForWallet.value;
        dataForm.wallet.value = -1;
    });

    watch(() => dataForm.algo_autoinversion_enabled, v => {
        if (v)
            dataForm.switch_tp.enabled = false;
    });

    watch(() => dataForm.switch_tp?.enabled, v => {
        if (v)
            dataForm.algo_autoinversion_enabled = false;
    });

    const initModule = () => {
        const preset = bots.presets[currPresetName.value]?.settings || {};
        const res = reactive(BOTS_DATA_FORM_FACTORY(preset, bots.notifications));

        for (const [key, value] of Object.entries(res)) {
            if (key === 'name') {
                dataForm[key] = {
                    value,
                    title: bots.localization['bot_name_f'],
                    dataHelp: bots.help['bot_name'],
                    placeholder: bots.localization['bot_name_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'exchange') {
                dataForm[key] = {
                    value,
                    title: bots.localization['exchange_f'],
                    dataHelp: bots.help['exchange'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'api_key') {
                dataForm[key] = {
                    value,
                    title: bots.localization['apikey_f'],
                    dataHelp: bots.help['api_key'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => !!(isExchangeSelected.value && apiKeysRefs.value)),
                    buttonLabel: bots.localization['bots_create_new_api_key'],
                    customField: true,
                };
            } else if (key === 'pair') {
                dataForm[key] = {
                    value,
                    title: bots.localization['bot/recommended_pairs/title'],
                    label: bots.localization['pair_f'],
                    howToChooseInfo: bots.localization['bots_how_to_choose_trade_pair'],
                    gridNavLabel: bots.localization['bot/recommended_pairs/show_per_page'],
                    recommendedPairsInfo: bots.localization['bot/recommended_pairs/info'],
                    pager: CRUD_PAGER_FACTORY(),
                    dataHelp: bots.help['pair'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => !!(isApiKeySelected.value && exchangeInfo.value)),
                    customField: true,
                };
            } else if (key === 'algo') {
                dataForm[key] = {
                    value,
                    title: bots.localization['algo_f'],
                    info: bots.localization['bots_bot_algorithms_info'],
                    dataHelp: bots.help['algo'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => !!(isPairSelected.value && bots.algos)),
                    customField: true,
                };
            } else if (key === 'wallet') {
                dataForm[key] = {
                    value,
                    title: bots.localization['depo_f'],
                    dataHelp: bots.help['depo'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => !!(isAlgoSelected.value && walletsRefs.value)),
                    buttonCreateLabel: bots.localization['bots_create_new_wallet'],
                    buttonCheckLabel: bots.localization['bots_check_balance'],
                    buttonCheckDisabled: computed(() => !isWalletSelected.value),
                    buttonEditLabel: bots.localization['bots_edit_wallet'],
                    buttonEditDisabled: computed(() => !isWalletSelected.value),
                    walletsRefs: computed(() => walletsRefs.value),
                    customField: true,
                };
            } else if (key === 'comm_type') {
                dataForm[key] = {
                    value,
                    label: bots.localization['commission_f'],
                    dataHelp: bots.help['commission'],
                    customField: true,
                };
            } else if (key === 'leverage') {
                dataForm[key] = {
                    value,
                    label: bots.localization['bot/leverage/label'],
                    info: bots.localization['bot/leverage/info'],
                    customField: true,
                };
            } else {
                dataForm[key] = value;
            }
        };

        init.value = true;
    };

    const exchange = (exchange) => {
        return refs.exchanges.find(el => el.id === exchange);
    };

    const getApiKeysList = async (id = dataForm.exchange.value) => {
        apiKeysRefs.value = ( await ApiKeysService.getApiKeysList({
            exchanges: [id],
            statuses: [1],
        }) ).data.records;
    };

    const walletReady = async $event => {
        setLoading('wallet');

        walletsRefs.value = ( await WalletsService.getWalletsList({
            exchanges: [dataForm.exchange.value],
            apikeys: [dataForm.api_key.value],
            pairs: [dataForm.pair.value],
        }) ).data.records;

        $event.api_key == dataForm.api_key.value
            ? dataForm.wallet.value = +$event.id
            : dataForm.wallet.value = -1;

        setLoading('wallet', false);
    };

    const walletUpdate = $event => {
        setLoading('wallet');

        dataForm.api_key.value = $event.api_key;

        if (dataForm.api_key.value === $event.api_key) {
            const i = walletsRefs.value.findIndex(({ id }) => id == $event.id);

            if (~i)
                walletsRefs.value[i] = $event;
        } else {
            dataForm.api_key.value = $event.api_key;
        }

        setLoading('wallet', false);
    };

    const setLoading = (key, v = true) => {
        const i = Object.keys(dataForm).findIndex(el => el === key);

        if (~i)
            Object.values(dataForm).slice(i).forEach(el => {
                if (typeof el === 'object')
                    el.loading = v;
            });
    };

    const updateSettings = $event => {
        dataForm.settings[$event[0]] = $event[1];
    };

    const onDeletePDFilter = i => {
        dataForm.switch_tp.pd_filters.splice(i, 1);
    };

    const onAddPDFilter = () => {
        dataForm.switch_tp.pd_filters.push(VA_PD_FILTER_FACTORY());
    };

    const updatePDFilter = ([ $event, i, field ]) => {
        dataForm.switch_tp.pd_filters[i][field] = +$event;
    };

    const onStoItemClicked = symbol => {
        dataForm.switch_tp.user_list.splice(dataForm.switch_tp.user_list.indexOf(symbol), 1);
    };

    const spreadSwitchTp = $event => {
        dataForm.switch_tp = { ...dataForm.switch_tp, ...$event };
    };

    const updateNotificationsContactsEmail = $event => {
        console.log('#event', $event);
    };

    const updateNotificationsContactsSms = $event => {
        console.log('#event', $event);
    };

    const onApplyClicked = async () => {
        gl.showLoading = true;

        const payload = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);
        payload.start_filters.forEach(el => {
            delete el.parent_id;
            delete el.changed;
        });
        
        payload.start_filters = (_.get(payload, 'start_filters', [])).map((sf, i) => ({...sf, pair: sf.pair == -1 || !sf.pair ? null : sf.pair, _formPath: `sf_${i}`}));
        payload.switch_tp.pd_filters = (_.get(payload, 'switch_tp.pd_filters', [])).map((filter, i) => ({_formPath: `pd_fitler_${i}`, ...filter}));
        payload['_formPath'] = 'bots.addNew';

        try {
            bots.clearErrors();

            const result = await BotsService.addNew({
                pager,
                records: [payload],
            });

            if (result.postMessages) {
                result.postMessages.forEach(el => {
                    notification[el.success ? 'success' : 'error']({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });
            };

            if (!result.data.status) {
                if (Object.keys(result.data.errors_form['bots.addNew'].fields).length) {
                    bots.errorsForm = result.data.errors_form['bots.addNew'].fields;

                    setTimeout(() => {
                        const selector = Object.keys(bots.errorsForm)[0];
    
                        const el = document.querySelector(`#${selector}`);
                        if (el)
                            el.scrollIntoView({ block: gl.isMobile ? 'start' : 'center', behavior: 'smooth' });
                    });
                } else if (Object.keys(result.data.errors_form['bots.addNew']?.innerForms).length) {
                    bots.setInnerForms({
                        data: result.data.errors_form['bots.addNew'].innerForms,
                        startFilters: payload.start_filters,
                    });
                }
            } else {
                const newBot = await BotsService.get({
                    pager: CRUD_PAGER_FACTORY({
                        id: [result.data.records[0].id],
                    }),
                });

                if (bots.dataTable?.records)
                    bots.dataTable.records.unshift(newBot.data.records[0]);

                // if (result.postMessages) {
                //     result.postMessages.forEach(el => {
                //         notification[el.success ? 'success' : 'error']({
                //             content: el.msg,
                //             duration: 2500,
                //             keepAliveOnHover: true,
                //         });
                //     });
                // };

                router.push({ name: 'bots.review' });
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        gl.showLoading = false;
    };

    const triggerFilter = id => {
        const localId = hasFilter(id);

        if(~localId) {
            dataForm.start_filters.splice(localId, 1);
        } else {
            const date = new Date();
            
            const { year, month, day, hours, minutes, seconds } = getDateCompoents(date);

            dataForm.start_filters.push({
                id,
                id_op: '==',
                value: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
                parent_id: -1,
                changed: false,
            });
        }
    };

    const hasFilter = id => {
        return dataForm.start_filters.findIndex(el => id == el.id);
    };

    const getDateCompoents = date => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

        const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

        return {
            year,
            month,
            day,
            hours,
            minutes,
            seconds,
        };
    };

    const setStartTime = (index, $event) => {
        const date = new Date($event);
        const { year, month, day, hours, minutes, seconds } = getDateCompoents(date);

        // const time = dataForm.start_filters[index].value.split(' ')[1];

        dataForm.start_filters[index].value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    onMounted(async () => {
        initModule();
    });

    onBeforeUnmount(() => {
        bots.dataForm = {};
    });

    const change = e => {
        dataForm.switch_tp.use_bw_list = e;
        // $emit('update:switch_tp-use_bw_list', $event)
    };

    return {
        gl,
        bots,
        init,
        pager,
        dataForm,
        pairsRefs,
        isUpdating,
        applyLabel,
        algoOptions,
        walletsRefs,
        apiKeysRefs,
        exchangeInfo,
        canCreateBot,
        tickerMessage,
        showStartTime,
        currPresetName,
        leverageMinMax,
        exchangesSorted,
        pairWalletsRefs,
        isWalletSelected,
        exchangeMeta2Info,
        exchangeMeta3Info,
        isExchangeFutures,
        recommendedPairsRef,
        coinSymbolForWallet,
        symbolsForMarketRefs,
        exchangeCommissionCoin,
        t,
        router,
        change,
        exchange,
        hasFilter,
        setStartTime,
        walletReady,
        walletUpdate,
        onAddPDFilter,
        triggerFilter,
        onApplyClicked,
        spreadSwitchTp,
        updateSettings,
        getApiKeysList,
        updatePDFilter,
        onDeletePDFilter,
        onStoItemClicked,
        updateNotificationsContactsSms,
        updateNotificationsContactsEmail,
        dateDisabled(ts) {
            return new Date(ts).getTime() < (new Date().getTime() - 86400000 * 60) || new Date(ts).getTime() > new Date().getTime();
        },
    };
}